import React from 'react';

import PROMO_EX from 'mocks/webflow/promo/ex';

import 'styles/webflow/normalize.scss';
import 'styles/webflow/webflow.scss';
import 'styles/webflow/screver-de.webflow.scss';

const PromoEx = () => {
  return (
    <>
      <div
        className="webflow webflow-cx-ex"
        dangerouslySetInnerHTML={{ __html: PROMO_EX }}
      ></div>
    </>
  );
};

export default PromoEx;
